import React from "react"
import "./design2.css"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Photo from "../components/Photo"
import Header from "../pages/header"
import "../styles/resources.scss"
import { Helmet } from "react-helmet"
import Footer from "../components/common/footer"
import {
  useTranslation,
  I18nextContext,
  Trans,
  Link,
} from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL

function formateDate(date) {
  let newDate = new Date(date * 1000).toDateString()
  return newDate
}

const Design2 = ({ data }) => {
  // const context = React.useContext(I18nextContext);
  const blog = data.markdownRemark;
  const location = useLocation()



  let slug = ""
  if (data.markdownRemark != null) {slug = data.markdownRemark.frontmatter.slug}

  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + `/blog/${slug}/`
      : GATSBY_LANDING_PAGE_URL + `/${language}/blog/${slug}/`

  const canoncalUrl = buildCanonicalUrl()
  // const { t } = useTranslation()

  return (
    <Layout>
      <Header />
      {blog !== null && (
        <>
          <div>
            <Helmet>
              <Link rel="canonical" href={canoncalUrl} />
              <title>{blog.frontmatter.title}-Logipulse</title>
              <description>{blog.frontmatter.Meta_Description}</description>
              <keywords>{blog.frontmatter.Meta_Keywords}</keywords>
                <meta property="og:locale" content={language}/>
                <meta property="og:type" content="article"></meta>
                <meta property="og:site_name" content="Logipulse"></meta>
                <meta property="og:title" content={blog.frontmatter.title}></meta>
                <meta property="og:url" content={location.href}></meta>
                <meta property="og:image" content={GATSBY_LANDING_PAGE_URL+blog.frontmatter.img.childImageSharp.fluid.src}></meta>
                <meta property="og:image:width" content="640"></meta>
                <meta property="og:image:height" content="360"></meta>
                <meta property="og:image:type" content="image/jpeg"></meta> 
            </Helmet>
            <div className="banner-feature">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="resources-content-imgs">
                      <div className="resources_in_details" lang={language}>
                        <Link to="/">
                          <Trans>HOME</Trans>
                        </Link>{" "}
                        /{" "}
                        <div className="featuress">
                          <Link to="/blog/">
                            <Trans>RESOURCES</Trans>
                          </Link>{" "}
                          / <Trans>ARTICLE</Trans>
                        </div>
                      </div>

                      <div className="blogsub_main" lang={language}>
                        {blog.frontmatter.title}
                      </div>
                      <div className="resources_sub_main" lang={language}>
                        <Trans>{formateDate(blog.frontmatter.date)}</Trans>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="postTemplate" lang={language}>
            <div className="leftSide" lang={language}>
              <div className="postImage">
                <img
                  src={blog.frontmatter.img.childImageSharp.fluid.src}
                  alt={blog.frontmatter.title}
                />
              </div>
              <div className="postTitle" lang={language}>
                {blog.frontmatter.title}
              </div>

              <div
                className="postDesc htmlRender"
                lang={language}
                dangerouslySetInnerHTML={{ __html: blog.html }}
              ></div>
            </div>
            <div className="rightSide" lang={language}>
              <div className="rel">
                <div className="head_mbl">
                  <Trans>RELATED ARTICLES</Trans>
                </div>
                <div className="img picimages" lang={language}>
                  <Link
                    to={"/blog/" + data.markdownRemark.frontmatter.slug1 + `/`}
                  >
                    <img
                      src={blog.frontmatter.thumb.childImageSharp.fluid.src}
                      alt={blog.frontmatter.title}
                    />
                  </Link>
                </div>
                <div className="date" lang={language}>
                  <Trans>{formateDate(blog.frontmatter.date)}</Trans>
                </div>
                <div className="heading" lang={language}>
                  {blog.frontmatter.desctitle}
                </div>
                <div className="desc" lang={language}>
                  {blog.frontmatter.desccontent}
                </div>
                <div className="others" lang={language}>
                  <Link
                    to={"/blog/" + data.markdownRemark.frontmatter.slug1 + `/`}
                  >
                    <Trans>Know More</Trans>
                    <Photo
                      src="KnowMore-Right Arrow-H12.svg"
                      class="testing-arrow"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="rel">
                <div className="img picimages">
                  <Link
                    to={"/blog/" + data.markdownRemark.frontmatter.slug2 + `/`}
                  >
                    <img
                      src={
                        blog.frontmatter.featuredImg.childImageSharp.fluid.src
                      }
                      alt={blog.frontmatter.title}
                    />
                  </Link>
                </div>
                <div className="date">
                  {formateDate(blog.frontmatter.date)}
                  {/* {blog.frontmatter.date} */}
                </div>
                <div className="heading">{blog.frontmatter.desctitle1}</div>
                <div className="desc">{blog.frontmatter.desccontent1}</div>
                <div className="others">
                  <Link
                    to={"/blog/" + data.markdownRemark.frontmatter.slug2 + `/`}
                  >
                    <Trans>Know More</Trans>
                    <Photo
                      src="KnowMore-Right Arrow-H12.svg"
                      class="testing-arrow"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
<div className="shareposttemplate">
        <Trans>If you found this article helpful, please share it:</Trans>
      </div>
      <div className="share_button_plugin">
       <a className="SocialShare" href={"https://www.facebook.com/sharer.php?url="+location.href}><Photo src="Facebook.png" alt="facebook" className="" /></a>
       <a className="SocialShare" href={"https://www.linkedin.com/sharing/share-offsite/?url="+location.href}><Photo src="linkedin.png" alt="facebook" className="" /></a>
       <a className="SocialShare" href={"https://twitter.com/intent/tweet?url="+location.href}><Photo src="Twitter.png" alt="facebook" className="" /></a>
      </div>
      <div class="end_divis_resources">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div class="col-sm-12">
              <Photo
                src="Bottom Image-11.svg"
                class="innerimage"
                alt="logipulse-footer-images"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
export default Design2

export const query = graphql`
  query getBlogs($slug: String, $slug1: String, $language: String!) {
    markdownRemark(
      frontmatter: {
        lang: { eq: $language }
        slug: { eq: $slug }
        slug1: { eq: $slug1 }
      }
    ) {
      html
      frontmatter {
        date
        desc
        desctitle
        desccontent
        desctitle1
        desccontent1
        slug
        slug1
        slug2
        title
        Meta_Description
        Meta_Keywords
        img {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 360) {
              src
            }
          }
        }
        featuredImg {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        thumb {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
