import React from 'react';
import '../styles/global.css';


function Layout(props) {
    return (
        <div>
             {props.children}
        </div>
    );
}

export default Layout;